body{
  overflow-x: hidden;
  padding-top: 4.5rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.checkmark[type="checkbox"] {
  appearance: none; 
  -webkit-appearance: none; 
  -moz-appearance: none; 
  width: 25px;
  border: 3px solid #22A699;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s;
  cursor: pointer;
}

.signuplist li::before{
  font-size: 1rem;
  content: '\2714';
  color: #0a8c7f;
  display: inline-block;
  width: 1em; /* Adjust as needed to control space between bullet and text */
  margin-right: 6px; /* Adjust as needed to control space between bullet and text */
}

.checkmark[type="checkbox"]:checked {
  border-color: #22A699;
  background-color: #22A699; /* Add a background color when checked */
}
.checkmark[type="checkbox"]::before {
  content: '\2714'; /* Unicode character for a checkmark */
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff; /* Checkmark color */
  font-size: 14px;
  visibility: hidden; /* Hide the checkmark by default */
}

/* Style for the checkmark when checkbox is checked */
.checkmark[type="checkbox"]:checked::before {
  visibility: visible;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.slick-prev:before, .slick-next:before{
  color: #b3c5c2 !important;
}

.slick-slide > div{
  margin: 0 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.wavy-background {
  position: relative;
  height: 200px; /* Adjust the height as needed */
  overflow: hidden;
}

.wave:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #a0aec0, #e2e8f0); /* Adjust the colors as needed */
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

