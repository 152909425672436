@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}


@layer components{
  .breakpointDashboard--container{
    @apply flex sm:flex-row sm:items-center flex-col lg:gap-4 md:gap-4 gap-6;
  }
  
  .breakpointBuy--container{
    @apply flex xl:flex-row sm:items-center flex-col lg:gap-4 md:gap-4 gap-6
  }

}

/* ScrollBar */
.scroll::-webkit-scrollbar {
  display: none;
}


html {
  scroll-behavior: smooth;
}



body {
  margin: 0;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Loader component design */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(
    255,
    255,
    255,
    0.7
  ); /* Adjust the background color and opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the loader is on top of other content */
}

.loader-container {
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* profile edit form */
/* 
form1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 600px;
}

labe {
  display: block;
  margin-bottom: 8px;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.form-group {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}

.form-group label {
  flex: 1;
}

.form-group input {
  flex: 2;
}

.terms {
  display: flex;
  align-items: flex-start;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #45a049;
} */
